<template>
  <component :is="activeModalId" v-if="activeModalId" v-bind="binding" />
</template>

<script lang="ts">
import { modalRegistry } from "../modal.registry";

// eslint-disable-next-line import/no-default-export
export default defineComponent({
  components: modalRegistry,
});
</script>

<script lang="ts" setup>
import { useActiveModal } from "../composables/modal-active-state.store";

const { id: activeModalId, binding } = useActiveModal();

watch(
  () => useRoute().name,
  () => {
    if (!activeModalId.value) return;

    useModal(activeModalId.value).close();
  },
);
</script>
