<template>
  <app-form>
    <div class="mb-6">
      <h3 class="flex items-center gap-2 text-lg">
        <v-icon
          class="bg-primary/10 text-primary rounded-lg p-2"
          :icon="icon"
          :size="36"
        />
        {{ title }}
      </h3>
    </div>

    <div>
      <slot />
    </div>

    <div class="mt-8 flex justify-end gap-4">
      <v-btn
        v-if="displayBackCta"
        variant="text"
        @click="utilities?.send({ type: 'back' })"
      >
        Previous
      </v-btn>
      <v-btn
        v-if="optional"
        color="primary"
        data-cy-skip
        variant="tonal"
        @click="emit('skip')"
      >
        Skip
      </v-btn>
      <v-btn color="primary" :disabled="disabled" type="submit">
        Continue
      </v-btn>
    </div>
  </app-form>
</template>

<script lang="ts" setup>
import { useCourseSessionRegistrationWizardUtilities } from "~/business-areas/course-session-registration/composables/course-session-registration-wizard.hook";

defineProps<{
  title: string;
  icon: string;
  optional?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  skip: [];
}>();

const utilities = useCourseSessionRegistrationWizardUtilities();

const displayBackCta = computed(() => {
  return !!utilities?.state.value?.can({ type: "back" });
});
</script>
