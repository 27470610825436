/// <reference types="@types/google.maps" />

import type { Ref } from "vue";

const getAddressComponentValueByType = (
  components: google.maps.GeocoderAddressComponent[],
  type: string,
) => {
  return components.find((component) => component.types.includes(type));
};

const toAppAddress = (
  components: google.maps.GeocoderAddressComponent[],
): Omit<
  DatabaseCompositeType<"address">,
  "id" | "label" | "latitude" | "longitude"
> => {
  return {
    street_number:
      getAddressComponentValueByType(components, "street_number")?.long_name ??
      "",
    street:
      getAddressComponentValueByType(components, "route")?.long_name ?? "",
    city:
      getAddressComponentValueByType(components, "locality")?.long_name ?? "",
    zip_code:
      getAddressComponentValueByType(components, "postal_code")?.long_name ??
      "",
    country:
      getAddressComponentValueByType(components, "country")?.long_name ?? "",
    country_code:
      getAddressComponentValueByType(components, "country")?.short_name ?? "",
  };
};

export const toGoogleMapsLink = (
  address: DatabaseCompositeType<"address">,
): string =>
  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    address.label ?? "",
  )}&query_place_id=${address.id}`;

export const useGooglePlacesAutocomplete = (
  inputRef: Ref<HTMLInputElement>,
  options: {
    onSelect: (address: DatabaseCompositeType<"address">) => void;
  },
) => {
  const {
    public: {
      google: {
        places: { apiKey },
      },
    },
  } = useRuntimeConfig();

  const onGoogleLoaded = () => {
    const autocomplete = new google.maps.places.Autocomplete(unref(inputRef), {
      fields: [
        "address_components",
        "place_id",
        "formatted_address",
        "geometry",
      ],
    });

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      const address = toAppAddress(place.address_components ?? []);

      options.onSelect({
        id: place.place_id ?? "",
        label: place.formatted_address ?? "",
        latitude: place.geometry?.location?.lat() as number,
        longitude: place.geometry?.location?.lng() as number,
        ...address,
      });
    });
  };

  if (process.client && window.google) {
    onMounted(onGoogleLoaded);
  } else {
    useScriptTag(
      `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback`,
      onGoogleLoaded,
    );
  }
};
