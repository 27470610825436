<template>
  <app-form-autocomplete
    v-model="model"
    v-model:query="query"
    class="min-w-[200px]"
    :clearable="clearable"
    data-cy-organization
    :hide-details="hideDetails"
    :hint="hint"
    item-title="item.name"
    item-value="item.id"
    :items="results"
    label="Organization"
    :multiple="multiple"
    :readonly="readonly"
    :required="required"
    :return-object="returnObject"
  >
    <template #item="{ item: value, props }">
      <v-list-item v-bind="props" :title="value.item.name">
        <template #prepend>
          <app-image
            :alt="value.item.name"
            class="mr-4"
            :height="25"
            :src="value.item.logo_url"
            :width="25"
          />
        </template>
      </v-list-item>
    </template>
  </app-form-autocomplete>
</template>

<script lang="ts" setup>
import { useFuse } from "@vueuse/integrations/useFuse";
const model = defineModel<
  DatabaseTable<"organizations">["id"] | DatabaseTable<"organizations">["id"][]
>();

defineProps<{
  required?: boolean;
  returnObject?: boolean;
  readonly?: boolean;
  multiple?: boolean;
  hint?: string;
  hideDetails?: boolean;
  clearable?: boolean;
}>();

const { data } = useAsyncData(
  async () => {
    const { data } = await useDatabaseClient().from("organizations").select();

    return data ?? [];
  },
  {
    default: () => [],
  },
);

const query = ref("");

const { results } = useFuse(query, data, {
  matchAllWhenSearchEmpty: true,
  fuseOptions: { keys: ["name"], threshold: 0.3 },
});
</script>
