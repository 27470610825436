<template>
  <draggable
    v-model="model"
    :animation="250"
    class="relative flex list-none flex-col gap-2"
    ghost-class="opacity-40"
    handle=".handle"
    :item-key="itemKey"
    tag="ul"
  >
    <template #item="{ element, index }">
      <li class="relative cursor-grab">
        <button
          class="handle absolute -left-3 -top-3 z-10 rounded-full bg-white p-1"
        >
          <app-icon icon="ph:dots-six-vertical" :size="20" />
        </button>

        <slot name="item" v-bind="{ item: element, index }" />
      </li>
    </template>
  </draggable>
</template>

<script lang="ts" setup>
import Draggable from "vuedraggable";

const properties = defineProps<{
  modelValue: unknown[];
  itemKey?: string;
}>();

const model = useVModel(properties, "modelValue");
</script>
