<template>
  <app-modal
    subtitle="Select user among the following list. Only attended users can be selected."
    title="Resend post session email for attendees"
    @submit="onSubmit"
  >
    <v-alert
      class="mb-6"
      color="warning"
      icon="ph:warning"
      title="This feature must be used carefuly : it implies sending emails to our users. Please think twice before clicking Confirm."
      variant="tonal"
    />
    <v-list v-model:selected="selection" select-strategy="classic">
      <v-list-item
        v-for="attendee in list"
        :key="attendee.user_id"
        color="primary"
        :disabled="!attendee.attended"
        :subtitle="attendee.user!.email"
        :title="attendee.user!.full_name!"
        :value="attendee.user_id"
      >
        <template #prepend>
          <app-avatar
            :full-name="attendee.user!.full_name"
            :src="attendee.user!.avatar_url"
          />
        </template>

        <template #append="{ isActive }">
          <v-switch color="primary" hide-details :model-value="isActive" />
        </template>
      </v-list-item>
    </v-list>
  </app-modal>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  course_id: DatabaseTable<"courses">["id"];
  course_session_id: DatabaseTable<"course_sessions">["id"];
}>();

const client = useDatabaseClient();

const { data: list } = useAsyncData(
  async () => {
    const { data } = await client
      .from("course_session_attendees")
      .select("*, user: users(*)")
      .eq("course_session_id", properties.course_session_id);

    return data ?? [];
  },
  {
    default: () => [],
  },
);

const selection = ref<string[]>([]);

const { showError, showSuccess } = useAlert();

async function onSubmit() {
  try {
    await $fetch(
      `/api/courses/${properties.course_id}/sessions/${properties.course_session_id}/emails/post-session-attendee/resend`,
      {
        method: "POST",
        body: {
          attendees: selection.value,
        },
      },
    );
    showSuccess("Emails sent!");
    useModal("postSessionAttendeeEmailResend").close({ confirmed: true });
  } catch (error) {
    showError((error as Error).message);
  }
}
</script>
