<template>
  <div class="grid grid-cols-2">
    <hubspot-deal-autocomplete
      v-model="dealIdModel"
      class="deal-autocomplete"
      data-cy-deal-autocomplete
      :required="requiredDealId"
    />

    <app-form-select
      v-model="lineItemIdsModel"
      class="line-item-select"
      data-cy-line-item-select
      :disabled="!dealIdModel || linesItems.length === 1"
      item-title="properties.name"
      item-value="id"
      :items="linesItems"
      label="Line items"
      multiple
      :required="requiredLineItemIds"
      :return-object="false"
      :rules="{
        maxLength: maxLineItems ? { max: maxLineItems } : undefined,
      }"
    >
      <template #selection="{ item }">
        <v-chip size="x-small">
          {{ typeof item === "string" ? item : item.properties.name }}
        </v-chip>
      </template>

      <template v-if="hints?.lineItems" #prepend-item>
        <p class="text-subtle px-4 pb-2 text-sm">
          {{ hints.lineItems }}
        </p>
      </template>
    </app-form-select>
  </div>
</template>

<script lang="ts" setup>
import type { HubspotLineItem } from "~~/model/hubspot.model";

const properties = defineProps<{
  dealId?: string | null;
  lineItemIds?: string[] | null;

  requiredDealId?: boolean;
  requiredLineItemIds?: boolean;

  maxLineItems?: number;

  hints?: {
    lineItems?: string;
  };
}>();

const emit = defineEmits<{
  "select-line-items": [payload: HubspotLineItem[]];
}>();

const dealIdModel = useVModel(properties, "dealId");
const lineItemIdsModel = useVModel(properties, "lineItemIds");

const linesItems = ref<HubspotLineItem[]>([]);

watch(
  dealIdModel,
  async (id) => {
    if (!id) {
      linesItems.value = [];
      lineItemIdsModel.value = [];
      return;
    }

    linesItems.value = await $fetch(`/api/hubspot/deals/${id}/line-items`);

    if (linesItems.value.length > 1 || linesItems.value.length === 0) return;

    lineItemIdsModel.value = linesItems.value[0]
      ? [linesItems.value[0].id]
      : [];
  },
  { immediate: true },
);

watch(lineItemIdsModel, (value) => {
  emit(
    "select-line-items",
    value
      ?.map((id) => linesItems.value.find((item) => item.id === id))
      .filter(Boolean) ?? [],
  );
});
</script>

<style scoped>
.deal-autocomplete :deep(.v-field) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.line-item-select :deep(.v-field) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
