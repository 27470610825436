<template>
  <course-session-registration-wizard-step
    :disabled="disabled"
    icon="ph:user"
    title="User selection"
    @submit="onSubmit"
  >
    <user-upsert-widget
      v-model:user="user"
      v-model:user-creation-request="userCreationRequest"
    />

    <template v-if="context.session.public">
      <div class="flex items-center justify-start gap-6">
        <div>
          <v-checkbox-btn v-model="free" data-cy-free label="Invited user *" />
        </div>

        <v-slide-x-transition>
          <div v-if="free">
            <v-switch
              v-model="prospect"
              color="primary"
              density="compact"
              hide-details
              label="Sales propsect"
            />
          </div>
        </v-slide-x-transition>
      </div>

      <v-alert class="mt-4" icon="ph:info">
        Invited user won't to be associated to a customer and won't lead to
        credit expense if member of an organization.
      </v-alert>

      <v-alert
        v-if="warningMessage"
        class="mt-2"
        color="warning"
        icon="ph:warning"
        variant="tonal"
      >
        {{ warningMessage }}
      </v-alert>
    </template>
  </course-session-registration-wizard-step>
</template>

<script lang="ts" setup>
import type { UserView } from "@pollen/core/model/user.model";

import { useCourseSessionRegistrationCreditCapacity } from "~/business-areas/course-session-registration/composables/course-session-registration-credit-capacity.hook";
import type {
  CourseSessionRegistrationWizardContext,
  CourseSessionRegistrationWizardEvent,
} from "~/business-areas/course-session-registration/course-session-registration.model";

const properties = defineProps<{
  context: CourseSessionRegistrationWizardContext;
}>();

const emit = defineEmits<{
  send: [event: CourseSessionRegistrationWizardEvent];
}>();

const user = ref<UserView | undefined>();
const userCreationRequest =
  ref<CourseSessionRegistrationWizardContext["user"]["creation_request"]>();
const free = ref(false);
const prospect = ref(false);

const { disabled, warningMessage } = useCourseSessionRegistrationCreditCapacity(
  computed(() =>
    free.value
      ? undefined
      : user.value?.organization_membership?.organization_id,
  ),
  properties.context,
);

function onSubmit() {
  if (user.value?.organization_membership)
    return emit("send", {
      type: "organization_member_selected",
      user: user.value,
      free: free.value,
      prospect: prospect.value,
    });

  if (user.value)
    return emit("send", {
      type: "user_selected",
      user: user.value,
      free: free.value,
      prospect: prospect.value,
    });

  if (userCreationRequest.value) {
    return emit("send", {
      type: "user_creation_request_submitted",
      request: userCreationRequest.value,
      free: free.value,
      prospect: prospect.value,
    });
  }
}
</script>
