<template>
  <v-chip
    close-icon="ph:x"
    label
    :prepend-icon="tag.icon"
    :size="size"
    :style="{
      backgroundColor: colors.bgColor,
      color: colors.textColor,
    }"
  >
    {{ tag.label }}
  </v-chip>
</template>

<script lang="ts" setup>
import type { TagView } from "@/business-areas/tag/tag.model";

const properties = defineProps<{
  tag: TagView;
  size?: string;
}>();

const colors = computed(() => {
  return {
    bgColor: hexToHSL(properties.tag.color, { luminance: 90, saturation: 75 }),
    textColor: hexToHSL(properties.tag.color, {
      luminance: 20,
      saturation: 75,
    }),
    bdColor: hexToHSL(properties.tag.color, { luminance: 80, saturation: 50 }),
  };
});
</script>
