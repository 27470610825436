<template>
  <course-session-registration-wizard-step
    :disabled="disabled"
    icon="ph:buildings"
    optional
    title="Organization selection"
    @skip="emit('send', { type: 'skip_organization_selection' })"
    @submit="
      emit('send', {
        type: 'organization_selected',
        organization: organization!,
      })
    "
  >
    <p class="text-subtle text-small mb-4">
      <strong>{{ userFirstName }}</strong>
      does not belong to any organization yet. By selecting an organization
      here, {{ userFirstName }} will be added to it as a member and that session
      will appear in Hub.<br />
      <template v-if="context.free">
        As you choose to register {{ userFirstName }} as an
        <strong>invited user</strong>, no credit will be spent on the selected
        organization account.
      </template>
    </p>
    <organization-autocomplete v-model="organization" required />

    <v-alert
      v-if="warningMessage"
      color="warning"
      icon="ph:warning"
      variant="tonal"
    >
      {{ warningMessage }}
    </v-alert>
  </course-session-registration-wizard-step>
</template>

<script lang="ts" setup>
import { useCourseSessionRegistrationCreditCapacity } from "~/business-areas/course-session-registration/composables/course-session-registration-credit-capacity.hook";
import type {
  CourseSessionRegistrationWizardContext,
  CourseSessionRegistrationWizardEvent,
} from "~/business-areas/course-session-registration/course-session-registration.model";

const properties = defineProps<{
  context: CourseSessionRegistrationWizardContext;
}>();

const emit = defineEmits<{
  send: [event: CourseSessionRegistrationWizardEvent];
}>();

const organization =
  ref<CourseSessionRegistrationWizardContext["attach_to_organization"]>();
const { disabled, warningMessage } = useCourseSessionRegistrationCreditCapacity(
  organization,
  properties.context,
);

const userFirstName = computed(
  () =>
    properties.context.user.existing?.first_name ??
    properties.context.user.creation_request?.first_name,
);
</script>
