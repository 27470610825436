<template>
  <app-modal :actions="false" :form="false" :width="700">
    <v-list-item
      class="!bg-primary/5 mb-8 !rounded-lg !p-4"
      :prepend-avatar="session.course.teacher.avatar_url!"
      :subtitle="$d(new Date(session.start_date!))"
      :title="session.course.title"
    />

    <transition
      enter-active-class="transition"
      enter-from-class="opacity-0"
      leave-active-class="transition"
      leave-to-class="opacity-0"
      mode="out-in"
    >
      <keep-alive>
        <component :is="view" :context="state.context" @send="send" />
      </keep-alive>
    </transition>
  </app-modal>
</template>

<script lang="ts" setup>
import { provideWizardUtilities } from "~/business-areas/course-session-registration/composables/course-session-registration-wizard.hook";
import type { SessionPageView } from "~/pages/sessions/[sessionId]/_includes/composables/session-view.hook";

const properties = defineProps<{
  session: SessionPageView;
}>();

const { close } = useModal("courseSessionRegistration");
const { view, send, state } = useCourseSessionRegistrationWizard(
  properties.session,
  { onComplete: () => close({ confirmed: true }) },
);

provideWizardUtilities({ send, state });
</script>
