<template>
  <v-textarea v-model="value" v-bind="bind" auto-grow :rows="rows" />
</template>

<script lang="ts" setup>
import {
  appFormInputProperties,
  useAppFormInput,
} from "../composables/form-input.hook";

const properties = defineProps({
  ...appFormInputProperties,
  rows: { type: Number, default: 2 },
});

const { value, bind } = useAppFormInput(properties);
</script>
