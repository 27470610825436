export function useFileUpload() {
  const client = useDatabaseClient();

  async function upload(
    file: File,
    { bucket = "private", path }: { bucket: string; path: string },
  ) {
    const { data, error } = await client.storage
      .from(bucket)
      .upload(path + "/" + file.name, file, { upsert: true });

    if (error) throw createError(error);

    return data!;
  }

  return { upload };
}
