<template>
  <app-dropzone :accept="accept" @drop="onDrop">
    <div class="grid place-content-center gap-4">
      <v-btn
        :color="errorMessage ? 'error' : undefined"
        variant="tonal"
        @click="open"
      >
        Select file
      </v-btn>
      <p class="text-subtle text-center text-sm">or drop it here</p>

      <v-chip v-if="file" label prepend-icon="ph:paperclip" size="x-small">
        {{ file.name }}
      </v-chip>
    </div>
  </app-dropzone>
</template>

<script lang="ts" setup>
import { useField } from "vee-validate";

import { slugify } from "~/shared/url/url.utils";

const model = defineModel<File | undefined>();

const properties = defineProps<{
  required?: boolean;
  accept?: string[];
}>();

const { open, onChange } = useFileDialog({
  accept: properties.accept ? properties.accept.join(";") : undefined,
});

const { value: file, errorMessage } = useField<typeof model.value>(
  uniqueFieldName(),
  computed(() => {
    return {
      required: properties.required ?? false,
    };
  }),
  {
    initialValue: model.value,
    syncVModel: true,
  },
);

function toFormattedFile(file: File) {
  Object.defineProperty(file, "name", {
    writable: true,
    value: slugify(file.name),
  });

  return file;
}

onChange((files) => {
  file.value = files?.[0] ? toFormattedFile(files[0]) : undefined;
});

function onDrop(files: File[] | null) {
  file.value = files?.[0] ? toFormattedFile(files[0]) : undefined;
}
</script>
