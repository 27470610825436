import { useMe } from "~/core/authentication/composables/authentication-me.hook";

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.name === "auth-callback" || to.name === "auth-v1-verify") return;

  const { isAdmin, isAuthenticated } = useMe();

  if (!isAdmin.value) {
    await useDatabaseClient().auth.signOut({ scope: "local" });
  }

  if (!isAuthenticated.value && !to.meta.public)
    return navigateTo("/auth/login");
});
