<template>
  <app-modal
    :submitting="status === 'pending'"
    title="Company edition"
    :width="400"
    @submit="onSubmit"
  >
    <div class="grid gap-4">
      <app-image-upload-widget
        v-model="form.logo_url"
        class="aspect-video w-full"
        data-cy-company-logo
        preset="avatars"
        required
      />

      <app-form-input-text
        v-model="form.name"
        data-cy-company-name
        hide-details
        label="Company name"
        required
      />
    </div>
  </app-modal>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  company?: DatabaseTable<"companies">;
}>();

const form = reactive<DatabaseInsertRequest<"companies">>({
  id: properties.company?.id,
  name: properties.company?.name ?? "",
  logo_url: properties.company?.logo_url ?? "",
});

const { close } = useModal("companyEditionModal");
const { execute: onSubmit, status } = useAsyncData(
  "company_creation",
  async () => {
    const { data } = await useDatabaseClient()
      .from("companies")
      .upsert(form)
      .select()
      .single();

    return close({ confirmed: true, payload: data });
  },
  {
    watch: [],
    immediate: false,
  },
);
</script>
