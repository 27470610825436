<template>
  <app-form-autocomplete
    v-model="model"
    v-model:query="query"
    item-title="email"
    item-value="id"
    :items="items"
    label="User"
    placeholder="Search by email"
    :required="required"
  >
    <template #item="{ item, props }">
      <v-list-item
        v-bind="props"
        density="compact"
        :subtitle="item.email"
        :title="item.full_name"
      />
    </template>

    <template v-if="$slots['no-data']" #no-data>
      <slot name="no-data" />
    </template>
  </app-form-autocomplete>
</template>

<script lang="ts" setup>
const model = defineModel<
  DatabaseTable<"users"> & {
    organization_membership?: DatabaseTable<"organization_members"> & {
      organization: DatabaseTable<"organizations">;
    };
    customer: DatabaseTable<"customers">;
  }
>({ default: undefined });
const query = defineModel<string>("query", { default: "" });

defineProps<{
  required: boolean;
}>();

const { data: items, execute } = useFetch("/api/users", {
  immediate: false,
  watch: false,
  query: { query },
  default: () => [],
});

watchDebounced(query, (value) => {
  if ((value ?? "").length < 5) {
    return;
  }

  execute();
});
</script>
