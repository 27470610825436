<template>
  <app-form-select
    v-model="model"
    class="min-w-[130px]"
    hide-details
    item-title="title"
    item-value="title"
    :items="items"
    label="Visibility"
  >
    <template #selection="{ item }">
      <tag-page-visibility-chip
        class="!cursor-pointer"
        :public="item.public"
        size="small"
      />
    </template>

    <template #item="{ item, props }">
      <v-list-item
        v-bind="props"
        :prepend-icon="item.icon"
        :subtitle="item.subtitle"
        :title="item.title"
      />
    </template>
  </app-form-select>
</template>

<script lang="ts" setup>
const publicModel = defineModel<boolean>("public", { required: true });

const items: {
  public: boolean;
  title: string;
  subtitle: string;
  icon: string;
}[] = [
  {
    title: "Private",
    public: false,
    icon: "ph:eye-slash",
    subtitle:
      "Only super-admin users will be able to view that tag page on Pollen website",
  },
  {
    title: "Public",
    public: true,
    icon: "ph:eye",
    subtitle:
      "Everyone will be able to view and search that course on Pollen website",
  },
];

const model = computed<(typeof items)[number]>({
  get() {
    return items.find((item) => item.public === publicModel.value)!;
  },
  set(item) {
    publicModel.value = item.public;
  },
});
</script>
