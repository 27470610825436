<template>
  <div
    ref="zone"
    class="border-primary/30 relative grid place-content-center rounded-lg border-2 border-dashed p-8 transition-all duration-500"
    :class="{
      '!border-primary bg-primary/10': isOverDropZone,
    }"
    data-cy-dropzone
  >
    <div>
      <slot />
    </div>

    <v-fade-transition>
      <div
        v-show="isOverDropZone"
        class="absolute bottom-2 left-0 grid w-full place-content-center"
      >
        <v-icon
          class="bg-primary block aspect-square animate-bounce rounded-full p-2 text-white shadow-lg"
          icon="ph:file-arrow-down"
          :size="40"
        />
      </div>
    </v-fade-transition>
  </div>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  accept?: string[];
}>();

const emit = defineEmits<{
  drop: [payload: File[] | null];
}>();

const zone = templateRef<HTMLDivElement>("zone");
const { isOverDropZone } = useDropZone(zone, {
  dataTypes: properties.accept,
  onDrop(file) {
    emit("drop", file);
  },
});
</script>
